.form-group {
  margin-bottom: 0px;

  label {
    text-transform:uppercase;
  }
}

.modal {
  text-transform:none!important;
}

.card {
  .form-group {
    margin-bottom: 20px;
    position:relative;

    label {
      &.strong {
        font-weight:bold;
      }
    }

    .file {
      margin-bottom:10px;
    }
    .fast-action {
      .icon, label {
        display:inline-block;
        cursor:pointer;
        margin:0;
        padding:0px;
        margin-left:10px;
        letter-spacing:1px;
        text-transform:capitalize;
      }

      cursor:pointer;
      display:flex;
      justify-content:flex-start;
      padding:5px;
      align-items:center;
      background-color:$primary;
      color:white;
      margin-bottom:10px;
    }

    .input-group-prepend {
      &.suffix, &.prefix {
        width:100%;
        text-align:right;
        display:block;
        .input-group-text {
          text-align:right;
          border:0px;
          padding:0px;
          background-color:rgba(0,0,0,0);
          opacity:0.8;
          font-size:0.8em;
        }
      }
    }
    .form-check-label {
      margin-bottom:-10px;
      padding-top:4px;
      font-size:0.8em;
      cursor:pointer;
      text-transform:none;
    }

    &.form-check {
      margin-bottom:15px!important;

      .form-check-input {
        margin-top:8px!important;
      }
    }

    &.image, 
    &.lock {
      .add {
        position:absolute;
        right:0px;
        top:15px;
      }
    }

    .input-group {
      margin-bottom:20px;
      justify-content:flex-start;
      align-items:center;
      display:inline-flex;

      input, 
      select {
        width:100%;
      }
    }

    .delete-inline { 
      border:1px solid $red!important;
      background-color:rgba(0,0,0,0)!important;
      color:$red!important;
      opacity:0.5;
      &:hover {
        box-shadow:none!important;
        opacity:1;
        color:white!important;
        background-color:$red!important;
      }
    }

    .delete {
      border:1px solid $red!important;
      background-color:rgba(0,0,0,0)!important;
      color:$red!important;
      opacity:0.5;
      width:100%;
      margin-top:-20px;
      &:hover {
        box-shadow:none!important;
        opacity:1;
      }
    }

    label {
      text-transform:uppercase;
      display:block;
      letter-spacing:1px;
      .btn {
        float:right;
        height:100%;
        padding:0px 10px;
        border:0;
        color:$secondary;
        border:0!important;

        &:hover {
          color:$textLight;
          background-color:$secondary;
          border:0!important;
        }

        &:focus {
          border:0!important;
          box-shadow:none;
        }
      }
    }
  }
}