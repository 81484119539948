@import url(https://fonts.googleapis.com/css2?family=Audiowide&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@100;300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+Paaji+2:wght@400;700&display=swap);

.roomTooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  margin-top: 20px;
  right: 20%;
  margin-right: -50px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.roomTooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.roomTooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.detailedMemberView {
  color: #001b48;
}

.atl-notification {
  max-height: 70vh;
  overflow-y: auto;
}

@charset "UTF-8";
/* ==========================================================================
1. State colors
========================================================================== */
/* ==========================================================================
2. Social colors
========================================================================== */
.titleFont {
  font-family: "Audiowide";
  text-transform: uppercase;
  font-size: 1.2em;
  color: #001b48; }

#logo,
.loaderLogoContainer {
  display: inline-block;
  position: relative; }
  #logo img,
  .loaderLogoContainer img {
    opacity: 0; }
  #logo:before,
  .loaderLogoContainer:before {
    background-image: url(/static/media/logo_alpha_dark.ecfa735c.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0px;
    bottom: 0px;
    top: 0px;
    right: 0px;
    content: "\3B1";
    color: white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em; }

#logoSvg circle:nth-child(1) {
  stroke: #ffffff00; }

#logoSvg circle:nth-child(2) {
  stroke: #ffffff00; }

#logoSvg circle:nth-child(3) {
  stroke: #ffffff00; }

#logoSvg circle:nth-child(4) {
  stroke: #ffffff00; }

.loaderLogoContainer:before {
  opacity: 0;
  -webkit-animation-name: loaderOpacityFrame;
  animation-name: loaderOpacityFrame;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out; }

#login #logo {
  display: block;
  box-sizing: border-box;
  background-image: url(/static/media/logo_alpha_dark.ecfa735c.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  top: -10% !important;
  width: 100px !important;
  height: 100px !important;
  padding-left: 180px;
  /* Equal to width of new image */ }

.titleFont {
  font-family: "Baloo Paaji 2";
  text-transform: uppercase;
  font-size: 1.2em;
  color: #464b5d; }

#close-item {
  color: white !important; }

.card .btn-secondary {
  color: white !important; }

.card-header {
  background-color: #464b5d !important;
  color: white; }
  .card-header h3 {
    letter-spacing: 1px;
    font-size: 1em; }

.form-group {
  margin-bottom: 0px; }
  .form-group label {
    text-transform: uppercase; }

.modal {
  text-transform: none !important; }

.card .form-group {
  margin-bottom: 20px;
  position: relative; }
  .card .form-group label.strong {
    font-weight: bold; }
  .card .form-group .file {
    margin-bottom: 10px; }
  .card .form-group .fast-action {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    padding: 5px;
    align-items: center;
    background-color: #464b5d;
    color: white;
    margin-bottom: 10px; }
    .card .form-group .fast-action .icon, .card .form-group .fast-action label {
      display: inline-block;
      cursor: pointer;
      margin: 0;
      padding: 0px;
      margin-left: 10px;
      letter-spacing: 1px;
      text-transform: capitalize; }
  .card .form-group .input-group-prepend.suffix, .card .form-group .input-group-prepend.prefix {
    width: 100%;
    text-align: right;
    display: block; }
    .card .form-group .input-group-prepend.suffix .input-group-text, .card .form-group .input-group-prepend.prefix .input-group-text {
      text-align: right;
      border: 0px;
      padding: 0px;
      background-color: rgba(0, 0, 0, 0);
      opacity: 0.8;
      font-size: 0.8em; }
  .card .form-group .form-check-label {
    margin-bottom: -10px;
    padding-top: 4px;
    font-size: 0.8em;
    cursor: pointer;
    text-transform: none; }
  .card .form-group.form-check {
    margin-bottom: 15px !important; }
    .card .form-group.form-check .form-check-input {
      margin-top: 8px !important; }
  .card .form-group.image .add, .card .form-group.lock .add {
    position: absolute;
    right: 0px;
    top: 15px; }
  .card .form-group .input-group {
    margin-bottom: 20px;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex; }
    .card .form-group .input-group input,
    .card .form-group .input-group select {
      width: 100%; }
  .card .form-group .delete-inline {
    border: 1px solid #ff7273 !important;
    background-color: rgba(0, 0, 0, 0) !important;
    color: #ff7273 !important;
    opacity: 0.5; }
    .card .form-group .delete-inline:hover {
      box-shadow: none !important;
      opacity: 1;
      color: white !important;
      background-color: #ff7273 !important; }
  .card .form-group .delete {
    border: 1px solid #ff7273 !important;
    background-color: rgba(0, 0, 0, 0) !important;
    color: #ff7273 !important;
    opacity: 0.5;
    width: 100%;
    margin-top: -20px; }
    .card .form-group .delete:hover {
      box-shadow: none !important;
      opacity: 1; }
  .card .form-group label {
    text-transform: uppercase;
    display: block;
    letter-spacing: 1px; }
    .card .form-group label .btn {
      float: right;
      height: 100%;
      padding: 0px 10px;
      border: 0;
      color: #ffaa64;
      border: 0 !important; }
      .card .form-group label .btn:hover {
        color: white;
        background-color: #ffaa64;
        border: 0 !important; }
      .card .form-group label .btn:focus {
        border: 0 !important;
        box-shadow: none; }

.btn {
  border-radius: 2px;
  transition: all 0.3s; }
  .btn:hover {
    box-shadow: 0 3px 6px -3px rgba(0, 27, 72, 0.42), 0 1px 5px 0px rgba(0, 0, 0, 0.12), 0 2px 2px -1px rgba(0, 27, 72, 0.2) !important;
    -webkit-box-shadow: 0 3px 6px -3px rgba(0, 27, 72, 0.42), 0 1px 5px 0px rgba(0, 0, 0, 0.12), 0 2px 2px -1px rgba(0, 27, 72, 0.2) !important;
    -moz-box-shadow: 0 3px 6px -3px rgba(0, 27, 72, 0.42), 0 1px 5px 0px rgba(0, 0, 0, 0.12), 0 2px 2px -1px rgba(0, 27, 72, 0.2) !important;
    cursor: pointer; }
  .btn.delete {
    border: 1px solid #ff7273 !important;
    background-color: rgba(0, 0, 0, 0) !important;
    color: #ff7273 !important;
    opacity: 0.5;
    width: 100%; }
    .btn.delete:hover {
      box-shadow: none !important;
      opacity: 1;
      background-color: #ff7273 !important;
      color: white !important; }

.btn-primary {
  background-color: #ffaa64 !important;
  border: 1px solid #ffaa64 !important;
  color: white !important;
  font-weight: bold; }
  .btn-primary[disabled] {
    background-color: #464b5d !important;
    border: 1px solid #464b5d !important; }
    .btn-primary[disabled]:hover {
      box-shadow: none;
      -webkit-transform: 0;
              transform: 0;
      cursor: not-allowed; }

.full-width {
  width: 100% !important; }

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100%;
  background-color: #464b5d99;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000; }

.loaderTimer {
  color: #fff;
  text-align: center;
  font-size: 2em;
  position: absolute;
  bottom: 10%; }

.countdownContainer {
  background-color: #464b5d99;
  width: 300px;
  min-height: 100px;
  z-index: 100;
  left: 150; }

.countdownContainer .counter {
  color: #fff;
  text-align: center; }

.countdownContainer .counter span {
  color: #fff;
  font-size: 2em;
  text-align: center; }

.countdownContainer .title {
  color: #fff;
  font-size: 1.5em;
  text-align: center; }

.countdownContainer .message {
  color: #fff;
  font-size: 0.8em;
  text-align: center; }

.maintenanceLoaderContainer {
  background-color: #001b48ad !important; }

.maintenanceData {
  position: absolute;
  top: 10%;
  color: #001b48;
  font-weight: 700;
  text-align: center;
  background: white;
  padding: 10;
  border-color: #001b48; }

.loaderLogo {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out; }

@-webkit-keyframes loaderOpacityFrame {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.2; } }

@keyframes loaderOpacityFrame {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.2; } }

.loader {
  max-width: 15rem;
  width: 100%;
  height: auto;
  stroke-linecap: round;
  position: absolute; }

.loaderContainer circle {
  fill: none;
  stroke-width: 3.5;
  -webkit-animation-name: preloader;
  animation-name: preloader;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-transform-origin: 170px 170px;
  transform-origin: 170px 170px;
  will-change: transform; }

.loaderContainer circle:nth-of-type(1) {
  stroke-dasharray: 550; }

.loaderContainer circle:nth-of-type(2) {
  stroke-dasharray: 500; }

.loaderContainer circle:nth-of-type(3) {
  stroke-dasharray: 450; }

.loaderContainer circle:nth-of-type(4) {
  stroke-dasharray: 300; }

.loaderContainer circle:nth-of-type(1) {
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s; }

.loaderContainer circle:nth-of-type(2) {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s; }

.loaderContainer circle:nth-of-type(3) {
  -webkit-animation-delay: -0.45s;
  animation-delay: -0.45s; }

.loaderContainer circle:nth-of-type(4) {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s; }

@-webkit-keyframes preloader {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes preloader {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

body {
  margin: 0;
  padding: 0;
  font-family: "Baloo Paaji 2" !important;
  color: #333; }

.page,
body {
  background-color: white !important; }

.bg-light {
  background-color: #00000000 !important; }

.pageContainer {
  margin-top: 80px;
  padding: 10px; }

h1,
h2,
h3,
h4,
h5 {
  text-transform: uppercase;
  font-weight: bold; }

#root {
  min-height: 100vh; }

button {
  text-transform: uppercase; }

.error {
  color: #ff7273; }

a:hover {
  text-decoration: none; }

.text-light {
  color: #333 !important;
  opacity: 0.7;
  font-size: 0.7em; }

.text-main {
  color: #333; }

.text-bigger {
  font-size: 1.8em; }

.interactive {
  cursor: pointer; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */ }

.modal-dialog {
  z-index: 1050; }

.alert {
  cursor: pointer;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.half-width {
  width: 50%; }

.bg-grey {
  background-color: #f3f4f6 !important; }

#login {
  display: flex;
  min-height: 90vh;
  justify-content: center;
  align-items: center; }
  #login .popup {
    box-shadow: 0 14px 26px -12px rgba(0, 27, 72, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 27, 72, 0.2) !important;
    -webkit-box-shadow: 0 14px 26px -12px rgba(0, 27, 72, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 27, 72, 0.2) !important;
    -moz-box-shadow: 0 14px 26px -12px rgba(0, 27, 72, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 27, 72, 0.2) !important;
    padding: 10px;
    position: relative;
    background-color: #f3f4f6; }
    #login .popup #logo {
      position: absolute;
      top: -30%;
      left: 50%;
      height: 100%;
      z-index: -20;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
    #login .popup #title {
      text-align: center;
      margin-bottom: 20px; }
    #login .popup label {
      text-transform: uppercase; }
    #login .popup button {
      width: 100%;
      margin-top: 20px; }

#mainnavbar nav {
  background-color: #464b5d;
  padding: 0px 0px !important;
  color: white; }
  #mainnavbar nav#main {
    background: linear-gradient(158deg, #464b5d 0%, #464b5d 27%, #464b5d 100%);
    justify-content: space-between;
    box-shadow: 0 3px 6px -3px rgba(0, 27, 72, 0.42), 0 1px 5px 0px rgba(0, 0, 0, 0.12), 0 2px 2px -1px rgba(0, 27, 72, 0.2) !important;
    -webkit-box-shadow: 0 3px 6px -3px rgba(0, 27, 72, 0.42), 0 1px 5px 0px rgba(0, 0, 0, 0.12), 0 2px 2px -1px rgba(0, 27, 72, 0.2) !important;
    -moz-box-shadow: 0 3px 6px -3px rgba(0, 27, 72, 0.42), 0 1px 5px 0px rgba(0, 0, 0, 0.12), 0 2px 2px -1px rgba(0, 27, 72, 0.2) !important; }
  #mainnavbar nav .delimiter {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  #mainnavbar nav #left-part,
  #mainnavbar nav #right-part {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    #mainnavbar nav #left-part #logo,
    #mainnavbar nav #right-part #logo {
      height: 100%;
      position: relative;
      margin-right: 10px;
      margin-left: 10px;
      padding: 5px; }
      #mainnavbar nav #left-part #logo .version-number,
      #mainnavbar nav #right-part #logo .version-number {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: -40%;
        color: white;
        z-index: -10;
        font-size: 0.7em;
        text-align: center;
        /*background-color:$secondary;*/
        opacity: 0.5;
        font-weight: bold; }
      #mainnavbar nav #left-part #logo img,
      #mainnavbar nav #right-part #logo img {
        height: 100%; }
    #mainnavbar nav #left-part .navbar-nav,
    #mainnavbar nav #right-part .navbar-nav {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 0; }
      #mainnavbar nav #left-part .navbar-nav li,
      #mainnavbar nav #right-part .navbar-nav li {
        display: inline-block;
        height: 100%;
        padding: 0; }
      #mainnavbar nav #left-part .navbar-nav .nav-item,
      #mainnavbar nav #right-part .navbar-nav .nav-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 80px; }
        #mainnavbar nav #left-part .navbar-nav .nav-item.dropdown,
        #mainnavbar nav #right-part .navbar-nav .nav-item.dropdown {
          width: 40px;
          margin: 0px; }
        #mainnavbar nav #left-part .navbar-nav .nav-item .dropdown-toggle,
        #mainnavbar nav #left-part .navbar-nav .nav-item a,
        #mainnavbar nav #right-part .navbar-nav .nav-item .dropdown-toggle,
        #mainnavbar nav #right-part .navbar-nav .nav-item a {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          color: white !important;
          text-transform: uppercase;
          font-size: 0.7em;
          padding: 10px; }
          #mainnavbar nav #left-part .navbar-nav .nav-item .dropdown-toggle svg,
          #mainnavbar nav #left-part .navbar-nav .nav-item a svg,
          #mainnavbar nav #right-part .navbar-nav .nav-item .dropdown-toggle svg,
          #mainnavbar nav #right-part .navbar-nav .nav-item a svg {
            display: block;
            width: 100%;
            text-align: center;
            font-size: 1.5em;
            margin-bottom: 5px;
            margin-top: 5px; }
          #mainnavbar nav #left-part .navbar-nav .nav-item .dropdown-toggle:after,
          #mainnavbar nav #left-part .navbar-nav .nav-item a:after,
          #mainnavbar nav #right-part .navbar-nav .nav-item .dropdown-toggle:after,
          #mainnavbar nav #right-part .navbar-nav .nav-item a:after {
            position: absolute;
            bottom: 12px;
            font-size: 1.3em;
            left: 50%;
            opacity: 0;
            -webkit-transform: translateX(-90%);
                    transform: translateX(-90%);
            text-align: center; }
          #mainnavbar nav #left-part .navbar-nav .nav-item .dropdown-toggle:hover,
          #mainnavbar nav #left-part .navbar-nav .nav-item a:hover,
          #mainnavbar nav #right-part .navbar-nav .nav-item .dropdown-toggle:hover,
          #mainnavbar nav #right-part .navbar-nav .nav-item a:hover {
            background-color: #ffaa64;
            color: white !important;
            font-weight: bold;
            cursor: pointer; }
        #mainnavbar nav #left-part .navbar-nav .nav-item .dropdown-container,
        #mainnavbar nav #right-part .navbar-nav .nav-item .dropdown-container {
          width: 100%;
          flex-direction: row; }
          #mainnavbar nav #left-part .navbar-nav .nav-item .dropdown-container a,
          #mainnavbar nav #right-part .navbar-nav .nav-item .dropdown-container a {
            color: #001b48 !important; }
            #mainnavbar nav #left-part .navbar-nav .nav-item .dropdown-container a:hover,
            #mainnavbar nav #right-part .navbar-nav .nav-item .dropdown-container a:hover {
              color: white !important; }
  #mainnavbar nav #right-part {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 0px;
    padding-right: 0px; }

#mainnavbar #underbar {
  display: flex;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.12);
  height: 30px;
  top: 60px;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  z-index: 100; }
  #mainnavbar #underbar [aria-label="breadcrumb"] {
    background-color: rgba(0, 0, 0, 0) !important;
    height: 100%;
    margin-left: 20px;
    color: #333; }
    #mainnavbar #underbar [aria-label="breadcrumb"] .breadcrumb {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 0px;
      margin: 0px;
      font-weight: bold;
      text-transform: uppercase; }
      #mainnavbar #underbar [aria-label="breadcrumb"] .breadcrumb :nth-child(2n) {
        margin-right: 3px; }
      #mainnavbar #underbar [aria-label="breadcrumb"] .breadcrumb #starter-icon {
        margin-right: 10px; }
      #mainnavbar #underbar [aria-label="breadcrumb"] .breadcrumb a {
        color: white; }
        #mainnavbar #underbar [aria-label="breadcrumb"] .breadcrumb a:hover {
          text-decoration: none;
          color: #464b5d; }

#dashboard .fill {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center; }

#dashboard .dashboard-option {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  #dashboard .dashboard-option .logo {
    max-width: 40%;
    height: auto;
    max-height: 20vh;
    object-fit: contain;
    margin: 20px 0px; }
  #dashboard .dashboard-option h4 {
    font-size: 1em !important; }
  #dashboard .dashboard-option a {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-transform: uppercase;
    color: #ffaa64;
    letter-spacing: 2px;
    text-align: center; }
    #dashboard .dashboard-option a:hover {
      text-decoration: none; }

.scenarioButton {
  padding: 0;
  cursor: pointer; }

.scenarioContent {
  background: #464b5d99;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  padding: 20px;
  color: #fff; }

.noScroll {
  overflow: hidden !important; }

.scenarioMeta {
  color: #ffffffaa; }

#discution-edition #leftColumn {
  padding-right: 0px !important; }

#scenario-edition,
#discution-edition {
  min-height: 50vh; }
  #scenario-edition #myTabContent,
  #discution-edition #myTabContent {
    height: 100%; }
    #scenario-edition #myTabContent #graph,
    #discution-edition #myTabContent #graph {
      height: 100%; }
      #scenario-edition #myTabContent #graph #graphContainer,
      #discution-edition #myTabContent #graph #graphContainer {
        height: 100%; }
  #scenario-edition #discussionMetaButton,
  #discution-edition #discussionMetaButton {
    margin-bottom: 20px; }
  #scenario-edition .meta-input,
  #discution-edition .meta-input {
    margin-bottom: 10px; }
    #scenario-edition .meta-input .btn svg,
    #discution-edition .meta-input .btn svg {
      margin-top: 10px; }
    #scenario-edition .meta-input .btndisabled, #scenario-edition .meta-input .btn.btn-outline-secondary,
    #discution-edition .meta-input .btndisabled,
    #discution-edition .meta-input .btn.btn-outline-secondary {
      display: none; }
    #scenario-edition .meta-input .btn.btn-warning,
    #discution-edition .meta-input .btn.btn-warning {
      background-color: #ffaa64;
      color: white;
      border-radius: 0px;
      border: 0px;
      padding-top: 0px; }
    #scenario-edition .meta-input .save-btn-container-discussion,
    #discution-edition .meta-input .save-btn-container-discussion {
      position: absolute;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      right: -40px;
      top: 4px;
      bottom: 13px;
      padding: 5px 0px; }
  #scenario-edition .nav-tabs,
  #discution-edition .nav-tabs {
    margin-bottom: 10px; }
    #scenario-edition .nav-tabs .nav-item,
    #discution-edition .nav-tabs .nav-item {
      text-transform: uppercase; }
      #scenario-edition .nav-tabs .nav-item a,
      #discution-edition .nav-tabs .nav-item a {
        color: #333;
        border-radius: 5px;
        border: 0;
        margin-right: 10px; }
        #scenario-edition .nav-tabs .nav-item a svg,
        #discution-edition .nav-tabs .nav-item a svg {
          margin-right: 10px; }
        #scenario-edition .nav-tabs .nav-item a.active,
        #discution-edition .nav-tabs .nav-item a.active {
          background-color: #464b5d;
          color: white; }
    #scenario-edition .nav-tabs .right-side-bar,
    #discution-edition .nav-tabs .right-side-bar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1 1; }
  #scenario-edition #leftColumn,
  #discution-edition #leftColumn {
    padding-right: 30px;
    transition: all 0.7s;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden; }
    #scenario-edition #leftColumn div .srd-link-layer,
    #scenario-edition #leftColumn div .srd-node-layer,
    #discution-edition #leftColumn div .srd-link-layer,
    #discution-edition #leftColumn div .srd-node-layer {
      overflow-y: unset; }
    #scenario-edition #leftColumn .sideButton,
    #discution-edition #leftColumn .sideButton {
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 5px;
      position: absolute;
      right: -5px;
      top: 0px;
      bottom: 0px; }
    #scenario-edition #leftColumn .fullHeightGraph,
    #discution-edition #leftColumn .fullHeightGraph {
      height: calc(100vh - 177px); }
      #scenario-edition #leftColumn .fullHeightGraph .diagram-column,
      #discution-edition #leftColumn .fullHeightGraph .diagram-column {
        height: 100%; }
  #scenario-edition #rightColumn,
  #discution-edition #rightColumn {
    display: flex;
    position: relative;
    transition: all 0.7s;
    overflow-x: hidden; }
    #scenario-edition #rightColumn #no-item-selected,
    #discution-edition #rightColumn #no-item-selected {
      margin-top: 36vh;
      letter-spacing: 2px;
      text-transform: uppercase;
      opacity: 0.5; }
    #scenario-edition #rightColumn #editionContainer,
    #discution-edition #rightColumn #editionContainer {
      padding: 5px;
      height: calc(100vh - 177px);
      overflow-y: hidden; }
      #scenario-edition #rightColumn #editionContainer.scroll-y,
      #discution-edition #rightColumn #editionContainer.scroll-y {
        overflow-y: auto; }
    #scenario-edition #rightColumn #close-item,
    #discution-edition #rightColumn #close-item {
      position: absolute;
      right: 15px;
      top: 10px;
      font-size: 1.3em; }
    #scenario-edition #rightColumn .message-edit,
    #discution-edition #rightColumn .message-edit {
      height: 100%;
      overflow-y: scroll !important; }
    #scenario-edition #rightColumn #updateitem,
    #scenario-edition #rightColumn .save-btn,
    #discution-edition #rightColumn #updateitem,
    #discution-edition #rightColumn .save-btn {
      position: absolute;
      right: 45px;
      top: 10px;
      cursor: pointer;
      font-size: 1.3em; }
      #scenario-edition #rightColumn #updateitem.right-stick,
      #scenario-edition #rightColumn .save-btn.right-stick,
      #discution-edition #rightColumn #updateitem.right-stick,
      #discution-edition #rightColumn .save-btn.right-stick {
        right: 15px; }
      #scenario-edition #rightColumn #updateitem.btn-secondary, #scenario-edition #rightColumn #updateitem.btn-outline-secondary,
      #scenario-edition #rightColumn .save-btn.btn-secondary,
      #scenario-edition #rightColumn .save-btn.btn-outline-secondary,
      #discution-edition #rightColumn #updateitem.btn-secondary,
      #discution-edition #rightColumn #updateitem.btn-outline-secondary,
      #discution-edition #rightColumn .save-btn.btn-secondary,
      #discution-edition #rightColumn .save-btn.btn-outline-secondary {
        background-color: rgba(0, 0, 0, 0);
        color: #333; }
      #scenario-edition #rightColumn #updateitem.btn-outline-secondary,
      #scenario-edition #rightColumn .save-btn.btn-outline-secondary,
      #discution-edition #rightColumn #updateitem.btn-outline-secondary,
      #discution-edition #rightColumn .save-btn.btn-outline-secondary {
        opacity: 0.3; }
      #scenario-edition #rightColumn #updateitem.btn-danger,
      #scenario-edition #rightColumn .save-btn.btn-danger,
      #discution-edition #rightColumn #updateitem.btn-danger,
      #discution-edition #rightColumn .save-btn.btn-danger {
        background-color: rgba(0, 0, 0, 0);
        color: #ffaa64; }
      #scenario-edition #rightColumn #updateitem.btn-warning,
      #scenario-edition #rightColumn .save-btn.btn-warning,
      #discution-edition #rightColumn #updateitem.btn-warning,
      #discution-edition #rightColumn .save-btn.btn-warning {
        background-color: rgba(0, 0, 0, 0);
        color: #ffaa64; }

.roommember {
  justify-content: flex-start;
  padding-top: 2px;
  align-items: center;
  font-size: 0.8em;
  font-weight: bold; }
  .roommember .badge {
    margin-top: 3px; }

.card-header {
  padding-top: 10px;
  padding-bottom: 5px; }
  .card-header h3 {
    font-size: 1.25em;
    letter-spacing: 2px; }

.partners-container .add {
  margin: 0 !important;
  margin-top: -20px !important;
  position: relative !important; }

.npcs .npc-list {
  height: calc(100vh - 250px);
  overflow-y: scroll; }

.click-layer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer; }

#translations .nav-tabs {
  margin-bottom: 10px;
  border: 0; }
  #translations .nav-tabs .nav-item {
    text-transform: uppercase; }
    #translations .nav-tabs .nav-item a {
      color: #333;
      border-radius: 5px;
      border: 0;
      margin-right: 10px; }
      #translations .nav-tabs .nav-item a svg {
        margin-right: 10px; }
      #translations .nav-tabs .nav-item a.active {
        background-color: #464b5d;
        color: white; }

#translations .preview-image {
  width: 100%;
  max-height: 25vh;
  height: 200px;
  object-fit: contain;
  object-position: center;
  position: relative; }
  #translations .preview-image.none {
    border: 0;
    opacity: 0.1; }

#translations .delete-inline {
  border: 1px solid #ff7273 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  color: #ff7273 !important;
  opacity: 0.5; }
  #translations .delete-inline:hover {
    box-shadow: none !important;
    opacity: 1;
    color: white !important;
    background-color: #ff7273 !important; }

#translations .delete {
  border: 1px solid #ff7273 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  color: #ff7273 !important;
  opacity: 0.5;
  width: 100%;
  margin-top: -20px; }
  #translations .delete:hover {
    box-shadow: none !important;
    opacity: 1; }

#graphContainer {
  padding: 0px;
  z-index: 1050; }
  #graphContainer .floating-bar {
    position: absolute;
    display: flex;
    right: -25%;
    bottom: 2%; }
    #graphContainer .floating-bar .zoom-button {
      background-color: #464b5d99;
      box-shadow: 0 14px 26px -12px rgba(0, 27, 72, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 27, 72, 0.2) !important;
      -webkit-box-shadow: 0 14px 26px -12px rgba(0, 27, 72, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 27, 72, 0.2) !important;
      -moz-box-shadow: 0 14px 26px -12px rgba(0, 27, 72, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 27, 72, 0.2) !important;
      border-radius: 5px;
      padding: 5px 10px;
      margin-right: 5px;
      height: 100%;
      display: inline-block;
      text-align: center;
      color: white;
      cursor: pointer;
      border: 0px; }
    #graphContainer .floating-bar .help .btn,
    #graphContainer .floating-bar .help .btn-secondary {
      border: 0px;
      background-color: #464b5d99;
      box-shadow: 0 14px 26px -12px rgba(0, 27, 72, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 27, 72, 0.2) !important;
      -webkit-box-shadow: 0 14px 26px -12px rgba(0, 27, 72, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 27, 72, 0.2) !important;
      -moz-box-shadow: 0 14px 26px -12px rgba(0, 27, 72, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 27, 72, 0.2) !important;
      border-radius: 5px;
      padding: 5px 10px;
      margin-right: 10px;
      height: 100%;
      display: inline-block;
      display: none;
      text-align: center;
      color: white;
      cursor: pointer;
      border: 0px;
      font-size: 0.7em;
      letter-spacing: 2px; }
  #graphContainer #widgets {
    position: absolute;
    top: 0px;
    padding: 0px;
    left: 0px;
    z-index: 10;
    bottom: 0px;
    padding: 0px;
    margin: 0px;
    max-width: 200px;
    overflow-x: hidden; }
    #graphContainer #widgets .form-group {
      margin-right: 0px;
      margin-top: 5px;
      width: 100%; }
      #graphContainer #widgets .form-group select {
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        color: white;
        border: 0;
        font-size: 0.7em;
        letter-spacing: 1; }
    #graphContainer #widgets .widget-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      background-color: none;
      overflow-x: hidden;
      overflow-y: scroll;
      background-color: #464b5d;
      padding: 0px;
      margin: 0px; }
      #graphContainer #widgets .widget-container .category {
        color: white;
        text-align: center;
        text-transform: uppercase;
        display: inline-block;
        letter-spacing: 2px;
        font-size: 0.7em;
        width: 100%;
        border-bottom: 1px solid white;
        padding-bottom: 5px;
        cursor: default; }
      #graphContainer #widgets .widget-container .widget-button {
        background-color: none;
        text-align: left;
        padding: 5px;
        width: 100%;
        cursor: move;
        /* fallback if grab cursor is unsupported */
        cursor: grab !important;
        cursor: -webkit-grab !important;
        margin-bottom: 5px; }
        #graphContainer #widgets .widget-container .widget-button .icon {
          width: 30px;
          display: inline-block;
          justify-content: center; }
          #graphContainer #widgets .widget-container .widget-button .icon:before {
            display: block;
            margin-left: 50%;
            -webkit-transform: translateX(-50%);
                    transform: translateX(-50%); }
        #graphContainer #widgets .widget-container .widget-button label {
          color: white;
          font-size: 0.7em;
          letter-spacing: 1px;
          margin-left: 5px;
          cursor: move;
          /* fallback if grab cursor is unsupported */
          cursor: grab !important;
          cursor: -webkit-grab !important; }

.hidden {
  display: none !important; }

.margin-top-reducer {
  display: block;
  margin-top: -30px;
  height: 10px;
  z-index: -1; }

.fresh-air {
  display: block;
  margin-bottom: 10px;
  height: 10px;
  z-index: -1; }

.float-right {
  float: right; }

.tutorialButtons {
  width: 200px;
  height: 100%;
  width: 200px;
  text-align: left;
  overflow-y: auto;
  transition: all 0.7s;
  box-shadow: 9px 0px 25px -4px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  color: rgb(0, 27, 72);
  background: #eeeff1;
  padding: 0px;
  margin: 0px;
}

.tutorialContent {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 5px;
}

.tutorialButton {
  width: 100%;
  background: #00000000;
}
.tutorialButton :focus {
  background: #00e0cd;
}
.tutorialButton :active {
  background: #00e0cd;
}
.tutorialButton :focus {
  background: #00e0cd;
}

.centeredImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@font-face {
  font-family: "Barlow";
  src: url(/static/media/Barlowregular.8377a297.ttf);
}
@font-face {
  font-family: "Audiowide";
  src: url(/static/media/Audiowideregular.1035cee0.ttf);
}
.side-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100vh;
  width: 200px;
  text-align: left;
  color: #fff;
  z-index: 999;
  overflow-y: auto;
  transition: all 0.7s;
  box-shadow: 9px 0px 25px -4px rgba(0, 0, 0, 0.04);
  overflow: hidden;
}
.sidebarSection {
  font-family: "Audiowide";
  font-size: 0.8em;
  color: #333;
}

.sideBar-button {
  border: transparent;
  background: transparent;
  font-family: "Audiowide";
  font-style: normal;
  font-size: 0.6em;
  color: #333;
}
.navbar {
  height: 60px !important;
  position: fixed !important;
  width: 100%;
  z-index: 999;
}
.sideLogo {
  width: 50%;
}
.side-navbar ul {
  padding: 0;
  width: 100%;
}
.side-navbar li {
  text-decoration: none;
  list-style: none;
}
.side-navbar.shrink {
  width: 100px;
  text-align: center;
}
.widthLogo {
  width: 50% !important;
}
.rotate {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.newitem-icon p {
  display: none;
}

.sideBar-button:before {
  font-weight: 900;
  font-family: "FontAwesome";
  font-size: 25px;
  color: rgb(0, 27, 72);
}
.tab-button:before {
  font-weight: 900;
  font-family: "FontAwesome";
  font-size: 12px;
  color: rgb(0, 27, 72);
  margin-right: 2px;
}
.npcs-icon:before {
  content: "\F0C0";
}

.editor-icon:before {
  content: "\F040";
}
.header-icon:before {
  content: "\F2C2";
}
.trads-icon:before {
  content: "\F0AC";
}
.export-icon:before {
  content: "\F093";
}
.inventory-icon:before {
  content: "\F187";
}
.add-icon:before {
  content: "\F0FE";
}
.files-icon:before {
  content: "\F093";
}
.home-icon:before {
  content: "\F015";
}
.map-icon:before {
  content: "\F59F";
}
.chat-icon:before {
  content: "\F086";
}
.diagram-icon:before {
  content: "\F542";
}
.stats-icon:before {
  content: "\F201";
}
.admin-icon:before {
  content: "\F084";
}
.ce-icon:before {
  content: "\F0B1";
}
.codes-icon:before {
  content: "\F13E";
}
.applications-icon:before {
  content: "\F10A";
}
.users-icon:before {
  content: "\F0C0";
}
.tools-icon:before {
  content: "\F0AD";
}
.releaseInfo-icon:before {
  content: "\F187";
}
.meta-icon:before {
  content: "\F05A";
}
.import-icon:before {
  content: "\F019";
}
.new-icon:before {
  content: "\F055";
}
.linkStyle {
  font-family: Barlow;
  margin-bottom: 1px;
  width: 100%;
  background: transparent;
  text-decoration: none;
  display: inline-block;
  border: none;
  padding: 10px;
  transition: all 0.6s ease 0s;
}
.linkStyle:focus {
  background: #00e0cd;
  outline: 0;
}
.linkStyle:hover {
  outline: 0;
  cursor: pointer;
  text-decoration: none;
  link-style: none;
  background: #00e0cd;
  letter-spacing: 1px;
  transition: all 0.3s ease 0s;
}
.settings-icon:before {
  content: "\F085";
}
@media (max-width: 1199px) {
  .side-navbar {
    left: 0;
    width: 70px;
    text-align: center;
  }

  .side-navbar.show-sm {
    left: 0 !important;
  }

  .side-navbar .brand-small {
    display: block;
  }
  .side-navbar .sidenav-heading {
    margin-left: 0;
    font-size: 0.8rem;
  }
  .side-navbar i {
    display: block;
    margin-right: 0;
    font-size: 1rem;
  }
  .titleSide {
    display: none;
  }
}

.AtlantideFont {
  font-family: "Audiowide";
  color: #333;
}
.navBarVersionNumber {
  color: #fff;
  font-size: 0.5em;
  padding-top: 10px;
  margin-right: 20px;
}
.BarlowFont {
  font-family: Barlow !important;
}
.breadcrumb {
  font-size: 0.7em;
}
.atlBreadcrumbBg {
  background-color: #00000000 !important;
}
.atlBreadcrumbItem a {
  color: #00e0cdcc;
}
/* Background color */
.bgDarkBlue {
  background-color: #001b48 !important;
}
.notifPane {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.bgAqua {
  background-color: #00e0cd !important;
}

.white {
  color: #fff !important;
}

.success {
  background-color: #e6ffed !important;
}
.warning {
  background-color: #fbefd2 !important;
}
.danger {
  background-color: #ffeef0 !important;
}

.LaunchScreen {
  display: table;
}

.component-controller {
  width: 100%;
  height: auto;
  padding: 1% 4% 0 4%;
  margin-top: 60px;
  z-index: 1;
}


.json {
  padding: 5% 0 5% 0;
}


.titleSide {
  padding-top: 1rem;
  margin-left: 10px;
  font-size: 17px;
}

.bar {
  width: 100%;
}

.buttonBack {
  text-align: center;
}

.buttonBackGroup {
  margin: 2% 25% 0 25%;
}

.right {
  width: 100%;
  float: right;
}

.LogoResp {
  float: left;
  width: 3rem;
  padding: 0;
}

.page {
  position: absolute;
  top: 0;
  right: 0;
  transition: width 0.3s linear;
  width: calc(100% - 200px);
  background-color: #f3f4f6;
  min-height: 100vh;
}

#statistics {
  width: 100%;
}

.big-stat {
  font-size: 45px;
  font-weight: 700;
}

.medium-stat {
  font-size: 30px;
  font-weight: 700;
}


.fill {
  height: 100%;
  width: 100%;
}

.no-margin {
  padding: 0px;
}

.fill {
  height: 100%;
  width: 100%;
}
.column {
  margin-bottom: 100px;
  padding-bottom: 100px;
}

.screenBlock {
  position: unset;
  margin-bottom: 10px;
}

.graphBlock {
  position: unset;
  margin-top: 10px;
}
.diagram-layer {
  display: flex;
}
.diagram-view {
  background-color: lightslategrey;
}


.chat-icon:before {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 32px;
  content: "\F086";
  color: rgb(0, 27, 72);
}
.message_graph_title:before {
  font-family:'FontAwesome';
  font-weight:400;
  font-size: 16px;
  content: "\F075";
  color: rgb(255, 255, 255);
}
.answer_graph_title:before{
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F086";
  color: rgb(255, 255, 255);
}
.answerOpened_graph_title:before{
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F27B";
  color: rgb(255, 255, 255);
}
.entryPoint_graph_title:before{
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F015";
  color: rgb(255, 255, 255);
}
.nextCustomAnswer:after {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F0E7";
  color: rgb(255, 255, 255);
  padding-right: 5px;
}
.defaultClass:after {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F069";
  color: rgb(255, 255, 255);
  padding-right: 5px;
}
.trigger_graph_title:before {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F0F3";
  color: rgb(255, 255, 255);
}
.AMS_graph_title:before {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F083";
  color: rgb(255, 255, 255);
}
.Anecdote_graph_title:before {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F129";
  color: rgb(255, 255, 255);
}
.Archive_graph_title:before {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F187";
  color: rgb(255, 255, 255);
}
.Checkpoint_graph_title:before {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F08D";
  color: rgb(255, 255, 255);
}
.Custom_graph_title:before {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F005";
  color: rgb(255, 255, 255);
}
.Document_graph_title:before {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F15B";
  color: rgb(255, 255, 255);
}
.Discussion_graph_title:before {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F086";
  color: rgb(255, 255, 255);
}
.Failure_graph_title:before {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F06A";
  color: rgb(255, 255, 255);
}
.GameArea_graph_title:before {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F279";
  color: rgb(255, 255, 255);
}
.Openable_graph_title:before {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F07C";
  color: rgb(255, 255, 255);
}
.POI_graph_title:before {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F041";
  color: rgb(255, 255, 255);
}
.Start_graph_title:before {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F015";
  color: rgb(255, 255, 255);
}
.SecondaryMission_graph_title:before {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F21B";
  color: rgb(255, 255, 255);
}
.Success_graph_title:before {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F058";
  color: rgb(255, 255, 255);
}
.Timer_graph_title:before {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F252";
  color: rgb(255, 255, 255);
}
.TimeTravel_graph_title:before {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F073";
  color: rgb(255, 255, 255);
}
.Tool_graph_title:before {
  font-family:'FontAwesome';
  font-weight:100;
  font-size: 16px;
  content: "\F0AD";
  color: rgb(255, 255, 255);
}

.srd-default-node__title:before {
  padding-left: 5px;
}

.srd-default-node__name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.entryPointName {
  color: #001B48;
}

.fill {
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-y:scroll;
  margin: 0;
}

.no-margin {
  padding: 0px;
}

#leftColumn {
  padding: 0;
}
#rightColumn {
  padding: 0;
  height: 100%;
  overflow: hidden;
}
#editionContainer {
  height: 100%;
  overflow: hidden;
}
.texteffet {
  -webkit-mask-image: -webkit-radial-gradient(
      left top,
      ellipse cover,
      rgb(0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 50%
    ),
    -webkit-radial-gradient(right bottom, ellipse cover, rgba(0, 0, 0, 0.1) 0%, rgb(
            0,
            0,
            0
          )
          30%),
    -webkit-radial-gradient(center bottom, ellipse cover, rgba(0, 0, 0, 0.1) 0%, rgba(
            0,
            0,
            0,
            0.7
          )
          10%, rgba(0, 0, 0, 0) 26%, rgba(0, 0, 0, 0) 100%),
    -webkit-radial-gradient(right top, ellipse cover, rgba(0, 0, 0, 0.1) 0%, rgba(
            0,
            0,
            0,
            0.7
          )
          20%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 100%);
  -webkit-mask-composite: xor;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  white-space: pre-line;
}

.editImageTextContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
}


