#mainnavbar {
  nav {
    background-color: $primary;
    padding: 0px 0px !important;
    color: $textLight;

    &#main {
      background: $primary-gradient;
      justify-content: space-between;
      box-shadow: $primary-box-shadow-small;
      -webkit-box-shadow: $primary-box-shadow-small;
      -moz-box-shadow: $primary-box-shadow-small;
    }

    .delimiter {
      transform: rotate(90deg);
    }

    #left-part,
    #right-part {
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      #logo {
        height: 100%;
        position: relative;
        margin-right: 10px;
        margin-left: 10px;
        padding: 5px;
        .version-number {
          position: absolute;
          left: 0px;
          right: 0px;
          bottom: -40%;
          color: $headerColor;
          z-index: -10;
          font-size: 0.7em;
          text-align: center;
          /*background-color:$secondary;*/
          opacity: 0.5;
          font-weight: bold;
        }
        img {
          height: 100%;
        }
      }

      .navbar-nav {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0;
        li {
          display: inline-block;
          height: 100%;
          padding: 0;
        }

        .nav-item {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 80px;

          &.dropdown {
            width: 40px;
            margin: 0px;
          }

          .dropdown-toggle,
          a {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: $headerColor !important;
            text-transform: uppercase;
            font-size: 0.7em;
            padding: 10px;

            svg {
              display: block;
              width: 100%;
              text-align: center;
              font-size: 1.5em;
              margin-bottom: 5px;
              margin-top: 5px;
            }

            &:after {
              position: absolute;
              bottom: 12px;
              font-size: 1.3em;
              left: 50%;
              opacity: 0;
              transform: translateX(-90%);
              text-align: center;
            }

            &:hover {
              background-color: $secondary;
              color: $headerColorHover !important;
              font-weight: bold;
              cursor: pointer;
            }
          }

          .dropdown-container {
            width: 100%;
            flex-direction: row;
            a {
              color: $headerColorSubmenu !important;
              &:hover {
                color: $headerColorHover !important;
              }
            }
          }
        }
      }
    }

    #right-part {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-right: 0px;
      padding-right: 0px;
    }
  }

  #underbar {
    display: flex;
    width: 100%;
    background-color: $primary-shadow;
    height: 30px;
    top: 60px;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    z-index: 100;

    [aria-label="breadcrumb"] {
      background-color: rgba(0, 0, 0, 0) !important;
      height: 100%;
      margin-left: 20px;
      color: $textMain;

      .breadcrumb {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0px;
        margin: 0px;
        font-weight: bold;
        text-transform: uppercase;

        :nth-child(2n) {
          margin-right: 3px;
        }

        #starter-icon {
          margin-right: 10px;
        }

        a {
          color: $textLight;
          &:hover {
            text-decoration: none;
            color: $primary;
          }
        }
      }
    }
  }
}
