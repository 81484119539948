#login {
  display: flex;
  min-height: 90vh;
  justify-content: center;
  align-items: center;

  .popup {
    box-shadow: $primary-box-shadow;
    -webkit-box-shadow: $primary-box-shadow;
    -moz-box-shadow: $primary-box-shadow;
    padding: 10px;
    position: relative;
    background-color: $system;

    #logo {
      position: absolute;
      top: -30%;
      left: 50%;
      height: 100%;
      z-index: -20;
      transform: translateX(-50%);
    }

    #title {
      text-align: center;
      margin-bottom: 20px;
    }
    label {
      text-transform: uppercase;
    }
    button {
      width: 100%;
      margin-top: 20px;
    }
  }
}
