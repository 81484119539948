.fill {
  height: 100%;
  width: 100%;
}
.column {
  margin-bottom: 100px;
  padding-bottom: 100px;
}

.screenBlock {
  position: unset;
  margin-bottom: 10px;
}

.graphBlock {
  position: unset;
  margin-top: 10px;
}
.diagram-layer {
  display: flex;
}
.diagram-view {
  background-color: lightslategrey;
}
