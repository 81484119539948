$primary: #464b5d;
$primaryAlpha: #464b5d99;
$secondary: #ffaa64;
$primary-gradient: linear-gradient(158deg, #464b5d 0%, #464b5d 27%, #464b5d 100%);
// $system: blue;
$systemBG: white;
$systemBG-light: #00000000;

// TOP BAR LOGO && LOADER LOGO
#logo,
.loaderLogoContainer {
  display: inline-block;
  position: relative;
  img {
    opacity: 0;
  }
  &:before {
    background-image: url("../images/logo_alpha_dark.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0px;
    bottom: 0px;
    top: 0px;
    right: 0px;
    content: "α";
    color: $textLight;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
  }
}


// LOADER
#logoSvg {
  circle:nth-child(1) {
    stroke: #ffffff00;
  }
  circle:nth-child(2) {
    stroke: #ffffff00;
  }
  circle:nth-child(3) {
    stroke: #ffffff00;
  }
  circle:nth-child(4) {
    stroke: #ffffff00;
  }
}

.loaderLogoContainer {
  &:before {
    opacity: 0;
    -webkit-animation-name: loaderOpacityFrame;
    animation-name: loaderOpacityFrame;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
}
// END LOADER

// LOGIN //
#login {
  #logo {
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-image: url("../images/logo_alpha_dark.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    top: -10% !important;
    width: 100px !important;
    height: 100px !important;
    padding-left: 180px; /* Equal to width of new image */
  }
}
// END LOGIN //

// FONTS //
@import url("https://fonts.googleapis.com/css2?family=Baloo+Paaji+2:wght@400;700&display=swap");

$corefont: "Baloo Paaji 2";

.titleFont {
  font-family: "Baloo Paaji 2";
  text-transform: uppercase;
  font-size: 1.2em;
  color: $primary;
}

// STYLING FIX //
#close-item {
  // Close icon of items details
  color: white !important;
}

.card {
  .btn-secondary {
    // save icon of items details
    color: white !important;
  }
}
.card-header {
  // item details title container
  background-color: $primary !important;
  color: white;

  h3 {
    letter-spacing: 1px;
    font-size: 1em;
  }
}
